import React from 'react'
import Navbar from '../components/header/Navbar';
import Footer from '../components/footer/Footer';

function Home() {
  return (
  <>
    <Navbar/>
    <section className='min-h-screen flex items-center justify-center'>
      <div className='grid content-center bg-gray-900 text-white absolute top-0 place-items-center'>
        <div className='lg:relative'>
          {/* TODO: fix size of hero image */}
          <div className='overflow-hidden 2xl:h-screen mb-10'>
            <img className='w-screen relative' src='./images/home/1.jpg' alt='2'/>
          </div>
          
          <div className='lg:absolute lg:left-1/8 lg:top-1/4'>
            <div className='grid grid-cols-1 place-items-center'>
              <h1 className=' text-2xl lg:text-3xl xl:text-4xl font-bold my-2 mx-18 text-center'> Discover the beauty of the world<br/> through my lens.</h1>
              <button className='font-bold bg-orange-500 mb-12 my-4 rounded-2xl w-32 h-8 lg:w-44 lg:h-12 lg:rounded-3xl lg:text-xl'>Book Now</button>
            </div>            
          </div>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 place-items-center gap-y-2 lg:gap-y-4 lg:gap-x-4 w-11/12 h-full'>
          <img className='w-11/12 lg:w-full md:w-5/6 rounded-md' src='./images/home/2.jpg' alt='2'/>
          <img className='w-11/12 lg:w-full md:w-5/6 rounded-md' src='./images/home/3.jpg' alt='3'/>
          <img className='w-11/12 lg:w-full md:w-5/6 rounded-md' src='./images/home/4.jpg' alt='4'/>
          <img className='w-11/12 lg:w-full md:w-5/6 rounded-md' src='./images/home/5.jpg' alt='5'/>
          <img className='w-11/12 lg:w-full md:w-5/6 rounded-md' src='./images/home/6.jpg' alt='6'/>
          <img className='w-11/12 lg:w-full md:w-5/6 rounded-md' src='./images/home/7.jpg' alt='7'/>
        </div>
        <Footer/>
      </div>
    </section>
  </>
  ) 
}

export default Home