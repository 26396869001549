import React from 'react'

function MultiImages() {
    const images = require.context('../../../public/images/gallery', false);
    const image_length = images.keys().map(image => images(image));
    
    let image_list = []

    for(let i = 0; i < image_length.length; i++){
        const image_path = `./images/gallery/website-${9 + i}.jpg`

        let image_title = {
            "title": image_path
        }

        image_list.push(image_title)
    }
    console.log(image_list)

  return (
  <>
    <div className=' columns-1 md:columns-2 xl:columns-3 2xl:columns-4 mx-10 mt-28'>{image_list.map((image) => {
        return <div>
                <img className='w-full rounded-lg mb-8' src={image.title} alt={image.title}/>
            </div>
    })}</div>
  </>
    
  )
}

export default MultiImages