import React from 'react'

function Footer() {
  return (
    <footer className="footer footer-center w-full p-4 bg-gray-900 text-gray-300">
      <div className="text-center">
        <p>
          Copyright © 2024 -
          <a className="font-semibold" href="mailto:donald.h.smith2@gmail.com">Hunter Smith</a>
        </p>
      </div>
    </footer>
  )
}

export default Footer