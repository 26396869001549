import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./pages/Home.js"
import About from "./pages/About.js"
import Contact from "./pages/Contact.js"
import Gallery from "./pages/Gallery.js"
import NoPage from "./pages/NoPage.js"

function Router() {
  return (
    <BrowserRouter>
        <Routes>
            <Route index element={<Home/>}/>
            <Route path='/home' element={<Home/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/gallery' element={<Gallery/>}/>
            <Route path="*" element={<NoPage/>}/>
        </Routes>
    </BrowserRouter>
  )
}

export default Router