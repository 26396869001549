import React from 'react'
import Navbar from '../components/header/Navbar';
import Footer from '../components/footer/Footer';

function About() {
  return (
  <>
    <Navbar/>
    <div className='absolute bg-gray-900 min-h-screen w-screen top-0 z-0'/>
    <section className= 'absolute bg-gray-900 text-white place-items-center z-20'>
        <div className='grid grid-cols-1 lg:grid-cols-2 md:gap-x-10 items-center mx-12 mb-10'>
          <div className='flex justify-end md:mb-12'>
            <img className='mb-12 md:my-auto lg:my-auto w-full xl:w-11/12 2xl:max-w-2xl' src='./images/about/8.jpg' alt='red mountain train tracks'/>
          </div>
        
          <div className='grid grid-cols-1 gap-y-10 md:gap-y-6  3xl:mr-96 xl:text-lg 2xl:text-2xl font-light items-center 2xl:w-3/5'>
            <p className=''>
            "Hey there, I'm Hunter Smith, a seasoned photographer hailing from the vibrant city of Birmingham, AL.
              With a decade of professional photography under my belt, I've cultivated a deep-seated passion for freezing moments in time,
              igniting from a burning desire to immortalize memories for generations to come.
            </p>
            <p>
              Specializing in the captivating realms of portraits, headshots, landscapes, and product photography,  
              I've honed my craft to offer a diverse range of visual storytelling. Whether it's capturing the raw emotion of a family gathering,  
              the professionalism exuded in a corporate headshot, or the breathtaking beauty of nature's landscapes, 
              I thrive on the thrill of encapsulating life's most precious moments in a single frame.
            </p>
            <p>
              My mission? To spread the enchantment of my art far and wide, weaving threads of timelessness into every photograph I take.
              Whether I'm capturing the warmth and connection of a family gathering or the quiet serenity of a tranquil sunset, 
              I aim to create memories that endure the test of time, serving as cherished mementos of the moments that matter most.
            </p>
            <p>
              So, whether you're seeking to immortalize a milestone, celebrate the beauty of the everyday,
              or simply capture a fleeting moment worth remembering, I'm here to turn your vision into reality.
              Let's embark on this captivating journey together, and let the magic of photography weave its spell upon your world."
            </p>
          </div>
        </div>
        <Footer/>
    </section>


  </>
    
  )
}

export default About