import React from 'react'
import Navbar from '../components/header/Navbar';
import Footer from '../components/footer/Footer';
import ContactMe from '../components/forms/ContactMe';

function Contact() {



  

  return (
    <>
    <div className='bg-gray-900'>
      <Navbar/>
      <ContactMe/>
      <Footer/>
    </div>
     
    </>
  )
}

export default Contact