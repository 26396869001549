import React from 'react'
import Navbar from '../components/header/Navbar'
import MultiImages from '../components/multi_images/Multi_images'

function Gallery() {

  return (
    <>
      <Navbar/>
      <div className='absolute top-0 bg-gray-900 w-screen'>
        <MultiImages/>
      </div>
    </>
    
  )
}

export default Gallery