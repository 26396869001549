import React from 'react'
import { useState } from 'react'

import { FiMenu } from "react-icons/fi";



function Navbar() {

    const [nav, setNav] = useState(false);

    const handleNav = () => {
        setNav(!nav)
    }

  return (
    <>
        {/* Desktop Menu */}
        <div className='relative z-40'>
            <div className="px-4 mx-auto max-w-7xl sm:px-6">
                <div className="relative pt-6 pb-16 sm:pb-24">
                    <nav className="relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
                        <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                            <div className="flex items-center justify-between w-full md:w-auto">
                                <a href="/home"><span className="sr-only">Hunter Smith Photography</span>
                                    <img className="w-auto h-8 sm:h-12 xl:h-14 " src="./images/logo/logo_only.png" alt='logo' loading="lazy" width="202" height="40"/>
                                </a>
                                <div className="flex items-center -mr-2 md:hidden">
                                    <button onClick={handleNav} className="inline-flex items-center justify-center p-2 text-white bg-transparent rounded-md text-3xl"  type="button" aria-expanded="false" >
                                        <FiMenu/>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="hidden md:flex md:space-x-10 list-none">
                            <li>
                                <a href="/home" className="text-xl font-normal text-white list-none hover:text-gray-200"
                                    target="">Home</a>
                            </li>
                            <li>
                                <a href="/gallery" className="text-xl font-normal text-white list-none hover:text-gray-200"
                                    target="">Gallery
                                </a>
                            </li>
                            <li>
                                <a href="/about" className="text-xl font-normal text-white list-none hover:text-gray-200"
                                    target="">About
                                </a>
                            </li>
                        </div>
                        <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                            <div className="inline-flex rounded-full shadow">
                                <a href='/contact'>
                                    <div href="/contact"
                                        className="inline-flex items-center px-4 py-2 text-base text-white bg-orange-500 border border-transparent rounded-full cursor-pointer font-bold hover:bg-gray-50 ">
                                        Contact Me
                                    </div>
                                </a>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>


        {/* Mobile Menu */}
        <div className={nav ? 'fixed top-0 right-0 md:hidden bg-gray-800 w-3/4 h-full rounded-l-xl z-30 ease-in-out duration-500' : 'invisible ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 right-0'}>
            <div className='mt-24'>
                <ul className={nav ? 'grid grid-cols-1 place-items-start ml-10 font-bold text-xl text-white' : "hidden"}>
                    <li className='my-5'>
                        <a href='/home'>Home</a>
                    </li>
                    <li className='my-5'>
                        <a href='/gallery'>Gallery</a>
                    </li>
                    <li className='my-5'>
                        <a href='/about'>About</a>
                    </li>
                    <li className='my-5'>
                        <a href='/contact'>Contact Me</a>
                    </li>
                </ul>
            </div>
        </div>


    </>
  )
}

export default Navbar